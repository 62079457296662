import React, { useState } from 'react';
import styled from 'styled-components';
import { Tree, Button, Select, Input } from 'antd';
import Inputs from '../../components/Inputs';
import HeaderSearch from '../components/headersearch';
import { useNavigate } from 'react-router-dom';
import SidebarMenu from '../components/auth/Sidebar';
const Header = styled.div`
height: 50px;


`;
const Dasboard = styled.div``;
const Sidebarinner = styled.div`
width: 90%;


`;
const Sidebar = styled.div`
width: 250px;

@media(max-width: 48rem){
display: none;
}
`;
const MenuSidebar = styled.div`
span, i{
font-size: 14px;
}
`;

const MobileSection = styled.div`
height: 55px;
display: none;

@media(max-width: 48rem){
display: flex;
}

`;

const ContentDisplay = styled.div``;



const UserportalOverview = () => {
    const [loadding, setloading] = useState(false);

    const [price_f, setprice_f] = useState('0');
    const [costprice_f, setcostprice_f] = useState('0');

    const [price, setprice] = useState(0)
    const [costprice, setcostprice] = useState(0);
    const [margin, setmargin] = useState(0);
    const [profit_f, setprofit_f] = useState("");

    const { Option } = Select;

    const format_price_1 = (e) => {
        let value = e.target.value;
        console.log(value)
        value = value.replace(/,/g, '');
        value = value.replace(/\D/g, '');
        setprice(Number(value))
        value = Number(value).toLocaleString();
        setprice_f(value);

    }
    const format_price_2 = (e) => {
        let value = e.target.value;
        console.log(value)
        value = value.replace(/,/g, '');
        value = value.replace(/\D/g, '');
        const newvalue = Number(value)

        value = Number(value).toLocaleString();
        setcostprice_f(value);
        setcostprice(newvalue)

    }

    function calculate_proift() {
        const total = price - costprice;
        const percentage = total / 100;

        setprofit_f(total.toLocaleString());
        setmargin(percentage);


    }

    const checkbox = (e) => {
        const parent = e.currentTarget.parentElement.parentElement.parentElement
        const currentTarget = e.currentTarget;
        currentTarget.classList.toggle('notactive');
        parent.classList.toggle('bg-gray-200');

    }


    const navigate = useNavigate();




    return (

        <div className="header_portal__ui flex flex-col  h-screen">
            <HeaderSearch />

            <MobileSection className='bg-blue-900 gap-3 items-center'>
                <div className=' flex items-center justify-center cursor-pointer ml-2 mini___icon rounded-md'>
                    <i className='fa-regular fa-bars text-white ' style={{ fontSize: 22 }}></i>
                </div>

                <div className='flex rounded-md search__input___data '>
                    <div className="flex items-center justify-center icon__md">
                        <i className='fa-solid fa-search text-white' style={{ fontSize: 16 }}></i>
                    </div>
                    <input type="text" className='px-2' style={{ backgroundColor: 'transparent' }} />
                </div>

                <div className='flex'>
                    <div className="  icon__bell_mobile  mr-2 flex  justify-center items-center rounded-md">
                        <i className='fa-regular fa-bell text-white' style={{ fontSize: 20 }}></i>
                    </div>

                    <div className="icon__bell_mobile bg-red-600  mr-2 flex  justify-center items-center rounded-md">
                        MS
                    </div>
                </div>
            </MobileSection>



            <Dasboard className='flex flex-1 '>
                <SidebarMenu />

                <ContentDisplay className='flex ContentDisplay flex-col  flex-1'>

                    <div className="container__wrapper_main_group">
                        <div className='mx-auto container__wrapper  productoverview__page'>
                            <div className='flex mt-5  justify-between'>
                                <div className='font-bold add_product_text'>Product</div>
                                <div className=' options__container gap-3 flex items-center justify-center'>
                                    <div className='portal_options rounded-md bg-gray-200'>
                                        Export
                                    </div>
                                    <div className='portal_options rounded-md bg-gray-200'>
                                        Import
                                    </div>
                                    <div className='portal_options font-medium cursor-pointer rounded-md  add_productss' onClick={() => navigate('/userportal_addproduct')}>
                                        Add Product
                                    </div>

                                </div>
                            </div>

                            <div className='bg-white mt-5 rounded-lg custome__table_portal pb-5'>
                                <div className='flex items-center justify-between table_option_controls'>
                                    <div className='ml-3 flex gap-4'>
                                        <div className='cursor-pointer table_option_controls_options rounded-md hover:bg-gray-300 active__product_tab'>
                                            All
                                        </div>
                                        <div className='cursor-pointer table_option_controls_options rounded-md hover:bg-gray-300'>
                                            Active
                                        </div>
                                        <div className='cursor-pointer table_option_controls_options rounded-md hover:bg-gray-300'>
                                            Draft
                                        </div>
                                        <div className='cursor-pointer table_option_controls_options rounded-md  hover:bg-gray-300'>
                                            Archieved
                                        </div>
                                    </div>
                                    <div className='flex gap-3 mr-3'>
                                        <div className="shadow flex gap-2 justify-center items-center rounded-lg  custome__table_portal_option">
                                            <i className='fa-regular fa-search'></i>
                                            <i className='fa-regular fa-filter'></i>
                                        </div>
                                        <div className="shadow flex justify-center   custome__table_portal_option  rounded-lg items-center ">
                                            <i className='fa-regular fa-arrow-up'></i>
                                            <i className='fa-regular fa-arrow-down'></i>

                                        </div>
                                    </div>
                                </div>
                                <div className="component_table_group">
                                    <table>
                                        <thead className='table_header'>
                                            <tr>
                                                <th>
                                                    <div className="rounded-md circled__id cursor-pointer"></div>

                                                </th>
                                                <th>Product</th>
                                                <th>Status</th>
                                                <th>Inventory</th>
                                                <th>Category</th>
                                                <th>Type</th>
                                                <th>Vendor</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table_body'>
                                            <tr className='tr__row hover:bg-gray-100'>
                                                <td>
                                                    <div className='flex gap-3 items-center id_image_group_row_table'>
                                                        <div className="rounded-md flex items-center justify-center circled__id notactive cursor-pointer" onClick={checkbox}>
                                                            <i className='fa-solid fa-square-check'></i>
                                                        </div>
                                                        <div className="image__picture shadow-md border rounded-md"></div>
                                                    </div>
                                                </td>
                                                <td>My Shoes</td>
                                                <td>
                                                    <div>
                                                        <span className='bg-green-300 rounded-md  active__slight'>Active</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-red-600'>0 in Stock</div>
                                                </td>
                                                <td>Home</td>
                                                <td>Real Estate</td>
                                                <td>my Own</td>
                                            </tr>




                                        </tbody>

                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                </ContentDisplay>
            </Dasboard>

        </div >
    )
}

export default UserportalOverview
