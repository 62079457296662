
import { Provider } from 'react-redux';
import store from './store/store';
import axios from 'axios';
import Layout from './layout';

import { useSelector } from 'react-redux';
import './styles/app.style.css';
import './styles/responsive.style.css';
import './styles/app.loader.css';
import Cookies from 'js-cookie';

axios.defaults.baseURL = '/server/public/api/';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

axios.interceptors.request.use((config) => {
  const token = Cookies.get('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});




export const clearAllCookies = () => {
  const allCookies = Cookies.get(); // Get all cookies
  for (let cookie in allCookies) {
    Cookies.remove(cookie); // Remove each cookie
  }
};



// import './App.css';

function App() {

  // const Cartitems = useSelector(state => state.cart);

  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );


}



export default App;
