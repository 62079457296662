import React, { useEffect, useState } from 'react'
import Index from '../../layout/components/Navigation';
import MyCard from '../../layout/components/store/SubcatTitle';
import { Container } from '../../layout/components/styles';
import Search from '../../layout/components/search';
import Footer from '../../layout/components/footer/footer';
import homeimg from '../../images/category/house.webp';
import gadgetsimg from '../../images/category/phones.webp';
import cartimg from '../../images/category/cart.webp';
import hobbies from '../../images/category/hobbies.webp';
import jobsimg from '../../images/category/jobs.webp';
import Electronicsimg from '../../images/category/electronicm.webp';
import Cartone from '../../layout/components/products/cartone';
import { useDispatch, useSelector } from 'react-redux';
import { isopen } from '../../store/theme/submenu.slice';
import axios from 'axios';






const Home = () => {
    const n1 = localStorage.getItem('category') ? JSON.parse(localStorage.getItem('category')) : [];
    const n2 = localStorage.getItem('subcategory') ? JSON.parse(localStorage.getItem('subcategory')) : [];
    const [category, setcategory] = useState(n1);
    const [subcategory, setsubcategory] = useState(n2);

    useEffect(() => {
        axios.get('productcategory/index').then((res) => {
            localStorage.setItem('category', JSON.stringify(res.data.data));
            setcategory(res.data.data);
        });

        axios.get('productcategory/sub/index').then((res) => {
            setsubcategory(res.data.data);
            localStorage.setItem('subcategory', JSON.stringify(res.data.data));

        });
    }, [])



    const dispatch = useDispatch();
    const submenuTheme = useSelector((state) => state.submenuSlice);
    const showsubmenu = () => {
        dispatch(isopen());
        console.log(submenuTheme)
    }





    return (
        <div>
            <Index />

            <Container>

                {/* <div className='my-10 relative rounded-md home_carousel'>
                    <div>

                    </div>

                    <div className="absolute flex justify-between  button_components">
                        <div className="rounded-full flex justify-center cursor-pointer items-center circles_rouded shadow-md">
                            <i className='fa-solid fa-chevron-left'></i>
                        </div>
                        <div className="rounded-full flex justify-center cursor-pointer items-center circles_rouded shadow-md">
                            <i className='fa-solid fa-chevron-right'></i>

                        </div>
                    </div>
                </div> */}

                <div className='mt-5 justify-center gap-5 flex  menu___wrapper_morethan mobile_components'>

                    {category.map((cat) => {

                        return <MyCard img={cat.image} name={cat.name} onclick={showsubmenu} />
                    })}


                </div>

                <div className=' p-5  desktop__data__underlined '>
                    <h4 className='font-bold heading_category ml-10 '>Popular Catgories</h4>
                    <div className='flex flex-wrap justify-center   gap-5 mt-5'>
                        {category.map((cat) => (

                            <div className='my_menu_class bg-gray-200 p-3 rounded'>

                                <div className='flex items-center text-blue-700'>

                                    <i className={`fa-regular ${cat.image}`} style={{ fontSize: 17, }}></i>
                                    <div className='font-bold ml-2 font__bolder '>{cat.name}</div>
                                </div>


                                <div>
                                    <div className='submenu__container_lg'>
                                        {subcategory.map((subcat) => {
                                            if (subcat.parentCategory === cat.id) {
                                                return <div className='mt-1'>
                                                    <a className=' transition-colors duration-300 ease-in-out hover:text-red-500' href="/products">{subcat.name}</a>
                                                </div>
                                            }
                                        })}
                                    </div>
                                    <a href='/product' className='text-blue-800 font-medium block'>All In {cat.name} <i className='fa-regular fa-arrow-right'></i> </a>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>


            </Container>


            <Footer />
        </div>
    )
}

export default Home;
