import React from 'react'
import { Container } from '../styles'

const Footer = () => {
    return (
        <div className='bg-gray-100  footer__wrapper'>

            <div className='flex flex-col justify-center items-center footer___first_group'>
                <div>
                    <h5 className='font-bold font__personalized'>See personalized recommendations</h5>
                    <button className='flex mx-auto justify-center items-center rounded-md mt-3 sign__in__account'>Sign in</button>
                    <div className='flex justify-center items-center mt-2'>new customer? <a href="/option_select">Start here</a></div>
                </div>
            </div>
            <div className="back_to_top flex justify-center items-center text-white font-bold">Back to top</div>
            <Container className='p--3 py-20'>

                <div className='group_menu'>

                </div>
                <p className='text-center text-gray-700'>
                    Copyright © 2023-2024 jabuh Inc. All Rights Reserved. Accessibility, User Agreement, Privacy, Consumer Health Data, Payments Terms of Use, Cookies, CA Privacy Notice, Your Privacy Choices and AdChoice

                </p>
            </Container>
        </div>
    )
}

export default Footer
